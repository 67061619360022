import { builderTrpc } from "@archetype/trpc-react";
import { Loader } from "@archetype/ui";
import { useUser } from "@clerk/nextjs";
import type { NextPage } from "next";
import { useRouter } from "next/router";
import { useEffect } from "react";
const Page: NextPage = () => {
  const router = useRouter();
  const {
    isSignedIn,
    isLoaded: isLoadedUser
  } = useUser();
  const {
    data: currentOrgQuery,
    isError: isErrorOrg,
    isLoading: isLoadingOrg
  } = builderTrpc.organization.current.useQuery();
  const currentOrg = currentOrgQuery?.org;
  useEffect(() => {
    if (isLoadedUser !== true) {
      return;
    }

    // Redirect to sign in if needed
    if (isSignedIn === false) {
      void router.push({
        pathname: "/sign-in/[[...index]]",
        query: {}
      });
    }

    // Then if we are signed in, we can check if we have an org

    if (isLoadingOrg && !isErrorOrg) {
      return;
    }

    // This is where, we can redirect to an org picker
    if (currentOrg == null) {
      void router.push({
        pathname: "/select-organization",
        query: {}
      });
      return;
    }
    void router.push({
      pathname: "/[orgId]/workspace/[versionType]",
      query: {
        orgId: currentOrg.id,
        versionType: "prod"
      }
    });
  }, [currentOrg, isLoadingOrg, router, isLoadedUser, isSignedIn, isErrorOrg]);
  return <div className="flex h-dvh w-full items-center justify-center" data-sentry-component="Page" data-sentry-source-file="index.tsx">
      <Loader text="Loading your workspace..." data-sentry-element="Loader" data-sentry-source-file="index.tsx" />
    </div>;
};
export default Page;